@font-face {
  font-family: 'ArialBold';
  src: url('./assets/fonts/arial.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: 'ArialExtraBold';
  src: url('./assets/fonts/arial.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: 'ArialSemiBold';
    src: url('./assets/fonts/arial.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: auto;
}

body {
  margin: 0;
}

.notification {
  font-family: 'Arial, sans-serif' !important;
}
